import React, { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IconButton, Tooltip } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { isEmpty } from 'lodash'
import {
  ISidebarProvider,
  SidebarContext,
} from '../../../../context/SidebarContext/Context'
import { TeamInterface } from '../../../coaches/store/coaches.types'
import {
  TeamContext,
  ITeamProvider,
} from '../../../../context/TeamContext/Context'
import {
  PaymentContext,
  IPaymentProvider,
} from '../../../../context/PaymentContext/Context'
import './Sidebar.scss'
import {
  selectIsTeamManager,
  selectIsAdmin,
} from '../../../auth/store/auth.selectors'
import { DashboardItem } from './DashboardItem'

import AddTeamDashboard from '../../../coaches/add-team-dashboard/AddTeamDashboard'
import TeamsPayment from '../../../subscription/ui/TeamsPayment'
import SubscriptionExpiredPrompt from '../../../subscription/ui/SubscriptionExpiredPrompt'
import { useSidebarTeams } from '../../../../hooks/useSIdebarTeams'

type Props = {}

export const DashBoardSection: React.FC<Props> = () => {
  const history = useHistory()
  const {
    data: managerTeams,
    loading: managerTeamsLoading,
    initialLoading,
    getData: refetchTeams,
  } = useSidebarTeams()

  const [managedTeams, setManagedTeams] = useState<TeamInterface[]>(
    managerTeams || []
  )
  const hasManagerAccess = useSelector(selectIsTeamManager)
  const isAdmin: boolean = useSelector(selectIsAdmin)
  const { selectedTeam, handleSelectTeam } = useContext(
    TeamContext
  ) as ITeamProvider
  const {
    isSubscriptionExpiredPromptOpen,
    isTeamsPaymentOpen,
    isTeamDashboardModalOpen,
    isRenewal,
    handleRenewSubscription,
    onCloseTeamDashboardModal,
    handleCloseSubscriptionExpiredModal,
    handleClosePaymentdModal,
    addTeamHandler,
    openAddNewDashboard,
  } = useContext(PaymentContext) as IPaymentProvider
  const { user, closeMobileSidebar } = useContext(
    SidebarContext
  ) as ISidebarProvider

  const isManagerHasNoTeam = () =>
    isEmpty(managerTeams) && !managerTeamsLoading && !initialLoading

  const handleAddNewDashboard = () => {
    closeMobileSidebar()
    openAddNewDashboard()()
  }

  useEffect(() => {
    refetchTeams()
  }, [])

  useEffect(() => {
    if (managerTeams?.length) {
      setManagedTeams(managerTeams)
    } else if (isManagerHasNoTeam() && hasManagerAccess) {
      history.push('/home/my-team/new')
    }
  }, [managerTeams, managerTeamsLoading, hasManagerAccess, initialLoading])

  return (
    <div className="sidebar-content">
      {!isAdmin && (
        <>
          <div className="dashboard-list">
            {managedTeams.map((team) => (
              <DashboardItem
                team={team}
                key={team.name}
                selectedTeam={selectedTeam}
                setSelectedTeam={handleSelectTeam}
                onClick={closeMobileSidebar}
              />
            ))}
            {hasManagerAccess && (
              <Tooltip
                hasArrow
                label="Purchase a Team Dashboard"
                placement="right"
              >
                <IconButton
                  onClick={handleAddNewDashboard}
                  aria-label="add-button"
                  icon={<FiPlus />}
                  className="add-dashboard"
                />
              </Tooltip>
            )}
          </div>

          <AddTeamDashboard
            isOpen={isTeamDashboardModalOpen}
            onClose={onCloseTeamDashboardModal}
            coachSubscriptionPackage={user?.coachSubscription}
          />
          <TeamsPayment
            isOpen={isTeamsPaymentOpen}
            onClose={handleClosePaymentdModal}
            addTeamHandler={addTeamHandler}
            renewalSuccess={handleCloseSubscriptionExpiredModal}
            teamId={selectedTeam?.id}
            isRenewal={isRenewal}
          />
          <SubscriptionExpiredPrompt
            isOpen={isSubscriptionExpiredPromptOpen}
            onSuccess={handleCloseSubscriptionExpiredModal}
            renew={handleRenewSubscription}
          />
        </>
      )}
    </div>
  )
}
